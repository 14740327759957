/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SearchImport } from './routes/search'
import { Route as PrivacyImport } from './routes/privacy'
import { Route as PostsImport } from './routes/posts'
import { Route as GenresImport } from './routes/genres'
import { Route as FreebiesImport } from './routes/freebies'
import { Route as FairUseImport } from './routes/fair-use'
import { Route as DonateKeyImport } from './routes/donate-key'
import { Route as DashboardImport } from './routes/dashboard'
import { Route as ChangelogImport } from './routes/changelog'
import { Route as AboutImport } from './routes/about'
import { Route as IndexImport } from './routes/index'
import { Route as SalesIndexImport } from './routes/sales/index'
import { Route as CollectionsIndexImport } from './routes/collections/index'
import { Route as TagsIdImport } from './routes/tags/$id'
import { Route as SellersIdImport } from './routes/sellers/$id'
import { Route as SandboxesIdImport } from './routes/sandboxes/$id'
import { Route as SalesIdImport } from './routes/sales/$id'
import { Route as PromotionsIdImport } from './routes/promotions/$id'
import { Route as ProfileIdImport } from './routes/profile/$id'
import { Route as OffersIdImport } from './routes/offers/$id'
import { Route as ItemsIdImport } from './routes/items/$id'
import { Route as CollectionsIdImport } from './routes/collections/$id'
import { Route as BuildsIdImport } from './routes/builds/$id'
import { Route as AuthLogoutImport } from './routes/auth/logout'
import { Route as AuthLoginImport } from './routes/auth/login'
import { Route as AuthCallbackImport } from './routes/auth/callback'
import { Route as SandboxesIdIndexImport } from './routes/sandboxes/$id/index'
import { Route as ProfileIdIndexImport } from './routes/profile/$id/index'
import { Route as OffersIdIndexImport } from './routes/offers/$id/index'
import { Route as ItemsIdIndexImport } from './routes/items/$id/index'
import { Route as SandboxesIdOffersImport } from './routes/sandboxes/$id/offers'
import { Route as SandboxesIdItemsImport } from './routes/sandboxes/$id/items'
import { Route as SandboxesIdBuildsImport } from './routes/sandboxes/$id/builds'
import { Route as SandboxesIdAssetsImport } from './routes/sandboxes/$id/assets'
import { Route as SandboxesIdAchievementsImport } from './routes/sandboxes/$id/achievements'
import { Route as OffersIdReviewsImport } from './routes/offers/$id/reviews'
import { Route as OffersIdRelatedImport } from './routes/offers/$id/related'
import { Route as OffersIdPriceImport } from './routes/offers/$id/price'
import { Route as OffersIdMetadataImport } from './routes/offers/$id/metadata'
import { Route as OffersIdMediaImport } from './routes/offers/$id/media'
import { Route as OffersIdItemsImport } from './routes/offers/$id/items'
import { Route as OffersIdChangelogImport } from './routes/offers/$id/changelog'
import { Route as OffersIdAchievementsImport } from './routes/offers/$id/achievements'
import { Route as ItemsIdImagesImport } from './routes/items/$id/images'
import { Route as ItemsIdBuildsImport } from './routes/items/$id/builds'
import { Route as ItemsIdAssetsImport } from './routes/items/$id/assets'
import { Route as BuildsIdItemsImport } from './routes/builds/$id/items'
import { Route as BuildsIdInstallOptionsImport } from './routes/builds/$id/install-options'
import { Route as BuildsIdFilesImport } from './routes/builds/$id/files'
import { Route as ProfileIdAchievementsSandboxImport } from './routes/profile/$id/achievements/$sandbox'

// Create/Update Routes

const SearchRoute = SearchImport.update({
  id: '/search',
  path: '/search',
  getParentRoute: () => rootRoute,
} as any)

const PrivacyRoute = PrivacyImport.update({
  id: '/privacy',
  path: '/privacy',
  getParentRoute: () => rootRoute,
} as any)

const PostsRoute = PostsImport.update({
  id: '/posts',
  path: '/posts',
  getParentRoute: () => rootRoute,
} as any)

const GenresRoute = GenresImport.update({
  id: '/genres',
  path: '/genres',
  getParentRoute: () => rootRoute,
} as any)

const FreebiesRoute = FreebiesImport.update({
  id: '/freebies',
  path: '/freebies',
  getParentRoute: () => rootRoute,
} as any)

const FairUseRoute = FairUseImport.update({
  id: '/fair-use',
  path: '/fair-use',
  getParentRoute: () => rootRoute,
} as any)

const DonateKeyRoute = DonateKeyImport.update({
  id: '/donate-key',
  path: '/donate-key',
  getParentRoute: () => rootRoute,
} as any)

const DashboardRoute = DashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => rootRoute,
} as any)

const ChangelogRoute = ChangelogImport.update({
  id: '/changelog',
  path: '/changelog',
  getParentRoute: () => rootRoute,
} as any)

const AboutRoute = AboutImport.update({
  id: '/about',
  path: '/about',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const SalesIndexRoute = SalesIndexImport.update({
  id: '/sales/',
  path: '/sales/',
  getParentRoute: () => rootRoute,
} as any)

const CollectionsIndexRoute = CollectionsIndexImport.update({
  id: '/collections/',
  path: '/collections/',
  getParentRoute: () => rootRoute,
} as any)

const TagsIdRoute = TagsIdImport.update({
  id: '/tags/$id',
  path: '/tags/$id',
  getParentRoute: () => rootRoute,
} as any)

const SellersIdRoute = SellersIdImport.update({
  id: '/sellers/$id',
  path: '/sellers/$id',
  getParentRoute: () => rootRoute,
} as any)

const SandboxesIdRoute = SandboxesIdImport.update({
  id: '/sandboxes/$id',
  path: '/sandboxes/$id',
  getParentRoute: () => rootRoute,
} as any)

const SalesIdRoute = SalesIdImport.update({
  id: '/sales/$id',
  path: '/sales/$id',
  getParentRoute: () => rootRoute,
} as any)

const PromotionsIdRoute = PromotionsIdImport.update({
  id: '/promotions/$id',
  path: '/promotions/$id',
  getParentRoute: () => rootRoute,
} as any)

const ProfileIdRoute = ProfileIdImport.update({
  id: '/profile/$id',
  path: '/profile/$id',
  getParentRoute: () => rootRoute,
} as any)

const OffersIdRoute = OffersIdImport.update({
  id: '/offers/$id',
  path: '/offers/$id',
  getParentRoute: () => rootRoute,
} as any)

const ItemsIdRoute = ItemsIdImport.update({
  id: '/items/$id',
  path: '/items/$id',
  getParentRoute: () => rootRoute,
} as any)

const CollectionsIdRoute = CollectionsIdImport.update({
  id: '/collections/$id',
  path: '/collections/$id',
  getParentRoute: () => rootRoute,
} as any)

const BuildsIdRoute = BuildsIdImport.update({
  id: '/builds/$id',
  path: '/builds/$id',
  getParentRoute: () => rootRoute,
} as any)

const AuthLogoutRoute = AuthLogoutImport.update({
  id: '/auth/logout',
  path: '/auth/logout',
  getParentRoute: () => rootRoute,
} as any)

const AuthLoginRoute = AuthLoginImport.update({
  id: '/auth/login',
  path: '/auth/login',
  getParentRoute: () => rootRoute,
} as any)

const AuthCallbackRoute = AuthCallbackImport.update({
  id: '/auth/callback',
  path: '/auth/callback',
  getParentRoute: () => rootRoute,
} as any)

const SandboxesIdIndexRoute = SandboxesIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => SandboxesIdRoute,
} as any)

const ProfileIdIndexRoute = ProfileIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ProfileIdRoute,
} as any)

const OffersIdIndexRoute = OffersIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => OffersIdRoute,
} as any)

const ItemsIdIndexRoute = ItemsIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ItemsIdRoute,
} as any)

const SandboxesIdOffersRoute = SandboxesIdOffersImport.update({
  id: '/offers',
  path: '/offers',
  getParentRoute: () => SandboxesIdRoute,
} as any)

const SandboxesIdItemsRoute = SandboxesIdItemsImport.update({
  id: '/items',
  path: '/items',
  getParentRoute: () => SandboxesIdRoute,
} as any)

const SandboxesIdBuildsRoute = SandboxesIdBuildsImport.update({
  id: '/builds',
  path: '/builds',
  getParentRoute: () => SandboxesIdRoute,
} as any)

const SandboxesIdAssetsRoute = SandboxesIdAssetsImport.update({
  id: '/assets',
  path: '/assets',
  getParentRoute: () => SandboxesIdRoute,
} as any)

const SandboxesIdAchievementsRoute = SandboxesIdAchievementsImport.update({
  id: '/achievements',
  path: '/achievements',
  getParentRoute: () => SandboxesIdRoute,
} as any)

const OffersIdReviewsRoute = OffersIdReviewsImport.update({
  id: '/reviews',
  path: '/reviews',
  getParentRoute: () => OffersIdRoute,
} as any)

const OffersIdRelatedRoute = OffersIdRelatedImport.update({
  id: '/related',
  path: '/related',
  getParentRoute: () => OffersIdRoute,
} as any)

const OffersIdPriceRoute = OffersIdPriceImport.update({
  id: '/price',
  path: '/price',
  getParentRoute: () => OffersIdRoute,
} as any)

const OffersIdMetadataRoute = OffersIdMetadataImport.update({
  id: '/metadata',
  path: '/metadata',
  getParentRoute: () => OffersIdRoute,
} as any)

const OffersIdMediaRoute = OffersIdMediaImport.update({
  id: '/media',
  path: '/media',
  getParentRoute: () => OffersIdRoute,
} as any)

const OffersIdItemsRoute = OffersIdItemsImport.update({
  id: '/items',
  path: '/items',
  getParentRoute: () => OffersIdRoute,
} as any)

const OffersIdChangelogRoute = OffersIdChangelogImport.update({
  id: '/changelog',
  path: '/changelog',
  getParentRoute: () => OffersIdRoute,
} as any)

const OffersIdAchievementsRoute = OffersIdAchievementsImport.update({
  id: '/achievements',
  path: '/achievements',
  getParentRoute: () => OffersIdRoute,
} as any)

const ItemsIdImagesRoute = ItemsIdImagesImport.update({
  id: '/images',
  path: '/images',
  getParentRoute: () => ItemsIdRoute,
} as any)

const ItemsIdBuildsRoute = ItemsIdBuildsImport.update({
  id: '/builds',
  path: '/builds',
  getParentRoute: () => ItemsIdRoute,
} as any)

const ItemsIdAssetsRoute = ItemsIdAssetsImport.update({
  id: '/assets',
  path: '/assets',
  getParentRoute: () => ItemsIdRoute,
} as any)

const BuildsIdItemsRoute = BuildsIdItemsImport.update({
  id: '/items',
  path: '/items',
  getParentRoute: () => BuildsIdRoute,
} as any)

const BuildsIdInstallOptionsRoute = BuildsIdInstallOptionsImport.update({
  id: '/install-options',
  path: '/install-options',
  getParentRoute: () => BuildsIdRoute,
} as any)

const BuildsIdFilesRoute = BuildsIdFilesImport.update({
  id: '/files',
  path: '/files',
  getParentRoute: () => BuildsIdRoute,
} as any)

const ProfileIdAchievementsSandboxRoute =
  ProfileIdAchievementsSandboxImport.update({
    id: '/achievements/$sandbox',
    path: '/achievements/$sandbox',
    getParentRoute: () => ProfileIdRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/about': {
      id: '/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof AboutImport
      parentRoute: typeof rootRoute
    }
    '/changelog': {
      id: '/changelog'
      path: '/changelog'
      fullPath: '/changelog'
      preLoaderRoute: typeof ChangelogImport
      parentRoute: typeof rootRoute
    }
    '/dashboard': {
      id: '/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    '/donate-key': {
      id: '/donate-key'
      path: '/donate-key'
      fullPath: '/donate-key'
      preLoaderRoute: typeof DonateKeyImport
      parentRoute: typeof rootRoute
    }
    '/fair-use': {
      id: '/fair-use'
      path: '/fair-use'
      fullPath: '/fair-use'
      preLoaderRoute: typeof FairUseImport
      parentRoute: typeof rootRoute
    }
    '/freebies': {
      id: '/freebies'
      path: '/freebies'
      fullPath: '/freebies'
      preLoaderRoute: typeof FreebiesImport
      parentRoute: typeof rootRoute
    }
    '/genres': {
      id: '/genres'
      path: '/genres'
      fullPath: '/genres'
      preLoaderRoute: typeof GenresImport
      parentRoute: typeof rootRoute
    }
    '/posts': {
      id: '/posts'
      path: '/posts'
      fullPath: '/posts'
      preLoaderRoute: typeof PostsImport
      parentRoute: typeof rootRoute
    }
    '/privacy': {
      id: '/privacy'
      path: '/privacy'
      fullPath: '/privacy'
      preLoaderRoute: typeof PrivacyImport
      parentRoute: typeof rootRoute
    }
    '/search': {
      id: '/search'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof SearchImport
      parentRoute: typeof rootRoute
    }
    '/auth/callback': {
      id: '/auth/callback'
      path: '/auth/callback'
      fullPath: '/auth/callback'
      preLoaderRoute: typeof AuthCallbackImport
      parentRoute: typeof rootRoute
    }
    '/auth/login': {
      id: '/auth/login'
      path: '/auth/login'
      fullPath: '/auth/login'
      preLoaderRoute: typeof AuthLoginImport
      parentRoute: typeof rootRoute
    }
    '/auth/logout': {
      id: '/auth/logout'
      path: '/auth/logout'
      fullPath: '/auth/logout'
      preLoaderRoute: typeof AuthLogoutImport
      parentRoute: typeof rootRoute
    }
    '/builds/$id': {
      id: '/builds/$id'
      path: '/builds/$id'
      fullPath: '/builds/$id'
      preLoaderRoute: typeof BuildsIdImport
      parentRoute: typeof rootRoute
    }
    '/collections/$id': {
      id: '/collections/$id'
      path: '/collections/$id'
      fullPath: '/collections/$id'
      preLoaderRoute: typeof CollectionsIdImport
      parentRoute: typeof rootRoute
    }
    '/items/$id': {
      id: '/items/$id'
      path: '/items/$id'
      fullPath: '/items/$id'
      preLoaderRoute: typeof ItemsIdImport
      parentRoute: typeof rootRoute
    }
    '/offers/$id': {
      id: '/offers/$id'
      path: '/offers/$id'
      fullPath: '/offers/$id'
      preLoaderRoute: typeof OffersIdImport
      parentRoute: typeof rootRoute
    }
    '/profile/$id': {
      id: '/profile/$id'
      path: '/profile/$id'
      fullPath: '/profile/$id'
      preLoaderRoute: typeof ProfileIdImport
      parentRoute: typeof rootRoute
    }
    '/promotions/$id': {
      id: '/promotions/$id'
      path: '/promotions/$id'
      fullPath: '/promotions/$id'
      preLoaderRoute: typeof PromotionsIdImport
      parentRoute: typeof rootRoute
    }
    '/sales/$id': {
      id: '/sales/$id'
      path: '/sales/$id'
      fullPath: '/sales/$id'
      preLoaderRoute: typeof SalesIdImport
      parentRoute: typeof rootRoute
    }
    '/sandboxes/$id': {
      id: '/sandboxes/$id'
      path: '/sandboxes/$id'
      fullPath: '/sandboxes/$id'
      preLoaderRoute: typeof SandboxesIdImport
      parentRoute: typeof rootRoute
    }
    '/sellers/$id': {
      id: '/sellers/$id'
      path: '/sellers/$id'
      fullPath: '/sellers/$id'
      preLoaderRoute: typeof SellersIdImport
      parentRoute: typeof rootRoute
    }
    '/tags/$id': {
      id: '/tags/$id'
      path: '/tags/$id'
      fullPath: '/tags/$id'
      preLoaderRoute: typeof TagsIdImport
      parentRoute: typeof rootRoute
    }
    '/collections/': {
      id: '/collections/'
      path: '/collections'
      fullPath: '/collections'
      preLoaderRoute: typeof CollectionsIndexImport
      parentRoute: typeof rootRoute
    }
    '/sales/': {
      id: '/sales/'
      path: '/sales'
      fullPath: '/sales'
      preLoaderRoute: typeof SalesIndexImport
      parentRoute: typeof rootRoute
    }
    '/builds/$id/files': {
      id: '/builds/$id/files'
      path: '/files'
      fullPath: '/builds/$id/files'
      preLoaderRoute: typeof BuildsIdFilesImport
      parentRoute: typeof BuildsIdImport
    }
    '/builds/$id/install-options': {
      id: '/builds/$id/install-options'
      path: '/install-options'
      fullPath: '/builds/$id/install-options'
      preLoaderRoute: typeof BuildsIdInstallOptionsImport
      parentRoute: typeof BuildsIdImport
    }
    '/builds/$id/items': {
      id: '/builds/$id/items'
      path: '/items'
      fullPath: '/builds/$id/items'
      preLoaderRoute: typeof BuildsIdItemsImport
      parentRoute: typeof BuildsIdImport
    }
    '/items/$id/assets': {
      id: '/items/$id/assets'
      path: '/assets'
      fullPath: '/items/$id/assets'
      preLoaderRoute: typeof ItemsIdAssetsImport
      parentRoute: typeof ItemsIdImport
    }
    '/items/$id/builds': {
      id: '/items/$id/builds'
      path: '/builds'
      fullPath: '/items/$id/builds'
      preLoaderRoute: typeof ItemsIdBuildsImport
      parentRoute: typeof ItemsIdImport
    }
    '/items/$id/images': {
      id: '/items/$id/images'
      path: '/images'
      fullPath: '/items/$id/images'
      preLoaderRoute: typeof ItemsIdImagesImport
      parentRoute: typeof ItemsIdImport
    }
    '/offers/$id/achievements': {
      id: '/offers/$id/achievements'
      path: '/achievements'
      fullPath: '/offers/$id/achievements'
      preLoaderRoute: typeof OffersIdAchievementsImport
      parentRoute: typeof OffersIdImport
    }
    '/offers/$id/changelog': {
      id: '/offers/$id/changelog'
      path: '/changelog'
      fullPath: '/offers/$id/changelog'
      preLoaderRoute: typeof OffersIdChangelogImport
      parentRoute: typeof OffersIdImport
    }
    '/offers/$id/items': {
      id: '/offers/$id/items'
      path: '/items'
      fullPath: '/offers/$id/items'
      preLoaderRoute: typeof OffersIdItemsImport
      parentRoute: typeof OffersIdImport
    }
    '/offers/$id/media': {
      id: '/offers/$id/media'
      path: '/media'
      fullPath: '/offers/$id/media'
      preLoaderRoute: typeof OffersIdMediaImport
      parentRoute: typeof OffersIdImport
    }
    '/offers/$id/metadata': {
      id: '/offers/$id/metadata'
      path: '/metadata'
      fullPath: '/offers/$id/metadata'
      preLoaderRoute: typeof OffersIdMetadataImport
      parentRoute: typeof OffersIdImport
    }
    '/offers/$id/price': {
      id: '/offers/$id/price'
      path: '/price'
      fullPath: '/offers/$id/price'
      preLoaderRoute: typeof OffersIdPriceImport
      parentRoute: typeof OffersIdImport
    }
    '/offers/$id/related': {
      id: '/offers/$id/related'
      path: '/related'
      fullPath: '/offers/$id/related'
      preLoaderRoute: typeof OffersIdRelatedImport
      parentRoute: typeof OffersIdImport
    }
    '/offers/$id/reviews': {
      id: '/offers/$id/reviews'
      path: '/reviews'
      fullPath: '/offers/$id/reviews'
      preLoaderRoute: typeof OffersIdReviewsImport
      parentRoute: typeof OffersIdImport
    }
    '/sandboxes/$id/achievements': {
      id: '/sandboxes/$id/achievements'
      path: '/achievements'
      fullPath: '/sandboxes/$id/achievements'
      preLoaderRoute: typeof SandboxesIdAchievementsImport
      parentRoute: typeof SandboxesIdImport
    }
    '/sandboxes/$id/assets': {
      id: '/sandboxes/$id/assets'
      path: '/assets'
      fullPath: '/sandboxes/$id/assets'
      preLoaderRoute: typeof SandboxesIdAssetsImport
      parentRoute: typeof SandboxesIdImport
    }
    '/sandboxes/$id/builds': {
      id: '/sandboxes/$id/builds'
      path: '/builds'
      fullPath: '/sandboxes/$id/builds'
      preLoaderRoute: typeof SandboxesIdBuildsImport
      parentRoute: typeof SandboxesIdImport
    }
    '/sandboxes/$id/items': {
      id: '/sandboxes/$id/items'
      path: '/items'
      fullPath: '/sandboxes/$id/items'
      preLoaderRoute: typeof SandboxesIdItemsImport
      parentRoute: typeof SandboxesIdImport
    }
    '/sandboxes/$id/offers': {
      id: '/sandboxes/$id/offers'
      path: '/offers'
      fullPath: '/sandboxes/$id/offers'
      preLoaderRoute: typeof SandboxesIdOffersImport
      parentRoute: typeof SandboxesIdImport
    }
    '/items/$id/': {
      id: '/items/$id/'
      path: '/'
      fullPath: '/items/$id/'
      preLoaderRoute: typeof ItemsIdIndexImport
      parentRoute: typeof ItemsIdImport
    }
    '/offers/$id/': {
      id: '/offers/$id/'
      path: '/'
      fullPath: '/offers/$id/'
      preLoaderRoute: typeof OffersIdIndexImport
      parentRoute: typeof OffersIdImport
    }
    '/profile/$id/': {
      id: '/profile/$id/'
      path: '/'
      fullPath: '/profile/$id/'
      preLoaderRoute: typeof ProfileIdIndexImport
      parentRoute: typeof ProfileIdImport
    }
    '/sandboxes/$id/': {
      id: '/sandboxes/$id/'
      path: '/'
      fullPath: '/sandboxes/$id/'
      preLoaderRoute: typeof SandboxesIdIndexImport
      parentRoute: typeof SandboxesIdImport
    }
    '/profile/$id/achievements/$sandbox': {
      id: '/profile/$id/achievements/$sandbox'
      path: '/achievements/$sandbox'
      fullPath: '/profile/$id/achievements/$sandbox'
      preLoaderRoute: typeof ProfileIdAchievementsSandboxImport
      parentRoute: typeof ProfileIdImport
    }
  }
}

// Create and export the route tree

interface BuildsIdRouteChildren {
  BuildsIdFilesRoute: typeof BuildsIdFilesRoute
  BuildsIdInstallOptionsRoute: typeof BuildsIdInstallOptionsRoute
  BuildsIdItemsRoute: typeof BuildsIdItemsRoute
}

const BuildsIdRouteChildren: BuildsIdRouteChildren = {
  BuildsIdFilesRoute: BuildsIdFilesRoute,
  BuildsIdInstallOptionsRoute: BuildsIdInstallOptionsRoute,
  BuildsIdItemsRoute: BuildsIdItemsRoute,
}

const BuildsIdRouteWithChildren = BuildsIdRoute._addFileChildren(
  BuildsIdRouteChildren,
)

interface ItemsIdRouteChildren {
  ItemsIdAssetsRoute: typeof ItemsIdAssetsRoute
  ItemsIdBuildsRoute: typeof ItemsIdBuildsRoute
  ItemsIdImagesRoute: typeof ItemsIdImagesRoute
  ItemsIdIndexRoute: typeof ItemsIdIndexRoute
}

const ItemsIdRouteChildren: ItemsIdRouteChildren = {
  ItemsIdAssetsRoute: ItemsIdAssetsRoute,
  ItemsIdBuildsRoute: ItemsIdBuildsRoute,
  ItemsIdImagesRoute: ItemsIdImagesRoute,
  ItemsIdIndexRoute: ItemsIdIndexRoute,
}

const ItemsIdRouteWithChildren =
  ItemsIdRoute._addFileChildren(ItemsIdRouteChildren)

interface OffersIdRouteChildren {
  OffersIdAchievementsRoute: typeof OffersIdAchievementsRoute
  OffersIdChangelogRoute: typeof OffersIdChangelogRoute
  OffersIdItemsRoute: typeof OffersIdItemsRoute
  OffersIdMediaRoute: typeof OffersIdMediaRoute
  OffersIdMetadataRoute: typeof OffersIdMetadataRoute
  OffersIdPriceRoute: typeof OffersIdPriceRoute
  OffersIdRelatedRoute: typeof OffersIdRelatedRoute
  OffersIdReviewsRoute: typeof OffersIdReviewsRoute
  OffersIdIndexRoute: typeof OffersIdIndexRoute
}

const OffersIdRouteChildren: OffersIdRouteChildren = {
  OffersIdAchievementsRoute: OffersIdAchievementsRoute,
  OffersIdChangelogRoute: OffersIdChangelogRoute,
  OffersIdItemsRoute: OffersIdItemsRoute,
  OffersIdMediaRoute: OffersIdMediaRoute,
  OffersIdMetadataRoute: OffersIdMetadataRoute,
  OffersIdPriceRoute: OffersIdPriceRoute,
  OffersIdRelatedRoute: OffersIdRelatedRoute,
  OffersIdReviewsRoute: OffersIdReviewsRoute,
  OffersIdIndexRoute: OffersIdIndexRoute,
}

const OffersIdRouteWithChildren = OffersIdRoute._addFileChildren(
  OffersIdRouteChildren,
)

interface ProfileIdRouteChildren {
  ProfileIdIndexRoute: typeof ProfileIdIndexRoute
  ProfileIdAchievementsSandboxRoute: typeof ProfileIdAchievementsSandboxRoute
}

const ProfileIdRouteChildren: ProfileIdRouteChildren = {
  ProfileIdIndexRoute: ProfileIdIndexRoute,
  ProfileIdAchievementsSandboxRoute: ProfileIdAchievementsSandboxRoute,
}

const ProfileIdRouteWithChildren = ProfileIdRoute._addFileChildren(
  ProfileIdRouteChildren,
)

interface SandboxesIdRouteChildren {
  SandboxesIdAchievementsRoute: typeof SandboxesIdAchievementsRoute
  SandboxesIdAssetsRoute: typeof SandboxesIdAssetsRoute
  SandboxesIdBuildsRoute: typeof SandboxesIdBuildsRoute
  SandboxesIdItemsRoute: typeof SandboxesIdItemsRoute
  SandboxesIdOffersRoute: typeof SandboxesIdOffersRoute
  SandboxesIdIndexRoute: typeof SandboxesIdIndexRoute
}

const SandboxesIdRouteChildren: SandboxesIdRouteChildren = {
  SandboxesIdAchievementsRoute: SandboxesIdAchievementsRoute,
  SandboxesIdAssetsRoute: SandboxesIdAssetsRoute,
  SandboxesIdBuildsRoute: SandboxesIdBuildsRoute,
  SandboxesIdItemsRoute: SandboxesIdItemsRoute,
  SandboxesIdOffersRoute: SandboxesIdOffersRoute,
  SandboxesIdIndexRoute: SandboxesIdIndexRoute,
}

const SandboxesIdRouteWithChildren = SandboxesIdRoute._addFileChildren(
  SandboxesIdRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/about': typeof AboutRoute
  '/changelog': typeof ChangelogRoute
  '/dashboard': typeof DashboardRoute
  '/donate-key': typeof DonateKeyRoute
  '/fair-use': typeof FairUseRoute
  '/freebies': typeof FreebiesRoute
  '/genres': typeof GenresRoute
  '/posts': typeof PostsRoute
  '/privacy': typeof PrivacyRoute
  '/search': typeof SearchRoute
  '/auth/callback': typeof AuthCallbackRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/logout': typeof AuthLogoutRoute
  '/builds/$id': typeof BuildsIdRouteWithChildren
  '/collections/$id': typeof CollectionsIdRoute
  '/items/$id': typeof ItemsIdRouteWithChildren
  '/offers/$id': typeof OffersIdRouteWithChildren
  '/profile/$id': typeof ProfileIdRouteWithChildren
  '/promotions/$id': typeof PromotionsIdRoute
  '/sales/$id': typeof SalesIdRoute
  '/sandboxes/$id': typeof SandboxesIdRouteWithChildren
  '/sellers/$id': typeof SellersIdRoute
  '/tags/$id': typeof TagsIdRoute
  '/collections': typeof CollectionsIndexRoute
  '/sales': typeof SalesIndexRoute
  '/builds/$id/files': typeof BuildsIdFilesRoute
  '/builds/$id/install-options': typeof BuildsIdInstallOptionsRoute
  '/builds/$id/items': typeof BuildsIdItemsRoute
  '/items/$id/assets': typeof ItemsIdAssetsRoute
  '/items/$id/builds': typeof ItemsIdBuildsRoute
  '/items/$id/images': typeof ItemsIdImagesRoute
  '/offers/$id/achievements': typeof OffersIdAchievementsRoute
  '/offers/$id/changelog': typeof OffersIdChangelogRoute
  '/offers/$id/items': typeof OffersIdItemsRoute
  '/offers/$id/media': typeof OffersIdMediaRoute
  '/offers/$id/metadata': typeof OffersIdMetadataRoute
  '/offers/$id/price': typeof OffersIdPriceRoute
  '/offers/$id/related': typeof OffersIdRelatedRoute
  '/offers/$id/reviews': typeof OffersIdReviewsRoute
  '/sandboxes/$id/achievements': typeof SandboxesIdAchievementsRoute
  '/sandboxes/$id/assets': typeof SandboxesIdAssetsRoute
  '/sandboxes/$id/builds': typeof SandboxesIdBuildsRoute
  '/sandboxes/$id/items': typeof SandboxesIdItemsRoute
  '/sandboxes/$id/offers': typeof SandboxesIdOffersRoute
  '/items/$id/': typeof ItemsIdIndexRoute
  '/offers/$id/': typeof OffersIdIndexRoute
  '/profile/$id/': typeof ProfileIdIndexRoute
  '/sandboxes/$id/': typeof SandboxesIdIndexRoute
  '/profile/$id/achievements/$sandbox': typeof ProfileIdAchievementsSandboxRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/about': typeof AboutRoute
  '/changelog': typeof ChangelogRoute
  '/dashboard': typeof DashboardRoute
  '/donate-key': typeof DonateKeyRoute
  '/fair-use': typeof FairUseRoute
  '/freebies': typeof FreebiesRoute
  '/genres': typeof GenresRoute
  '/posts': typeof PostsRoute
  '/privacy': typeof PrivacyRoute
  '/search': typeof SearchRoute
  '/auth/callback': typeof AuthCallbackRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/logout': typeof AuthLogoutRoute
  '/builds/$id': typeof BuildsIdRouteWithChildren
  '/collections/$id': typeof CollectionsIdRoute
  '/promotions/$id': typeof PromotionsIdRoute
  '/sales/$id': typeof SalesIdRoute
  '/sellers/$id': typeof SellersIdRoute
  '/tags/$id': typeof TagsIdRoute
  '/collections': typeof CollectionsIndexRoute
  '/sales': typeof SalesIndexRoute
  '/builds/$id/files': typeof BuildsIdFilesRoute
  '/builds/$id/install-options': typeof BuildsIdInstallOptionsRoute
  '/builds/$id/items': typeof BuildsIdItemsRoute
  '/items/$id/assets': typeof ItemsIdAssetsRoute
  '/items/$id/builds': typeof ItemsIdBuildsRoute
  '/items/$id/images': typeof ItemsIdImagesRoute
  '/offers/$id/achievements': typeof OffersIdAchievementsRoute
  '/offers/$id/changelog': typeof OffersIdChangelogRoute
  '/offers/$id/items': typeof OffersIdItemsRoute
  '/offers/$id/media': typeof OffersIdMediaRoute
  '/offers/$id/metadata': typeof OffersIdMetadataRoute
  '/offers/$id/price': typeof OffersIdPriceRoute
  '/offers/$id/related': typeof OffersIdRelatedRoute
  '/offers/$id/reviews': typeof OffersIdReviewsRoute
  '/sandboxes/$id/achievements': typeof SandboxesIdAchievementsRoute
  '/sandboxes/$id/assets': typeof SandboxesIdAssetsRoute
  '/sandboxes/$id/builds': typeof SandboxesIdBuildsRoute
  '/sandboxes/$id/items': typeof SandboxesIdItemsRoute
  '/sandboxes/$id/offers': typeof SandboxesIdOffersRoute
  '/items/$id': typeof ItemsIdIndexRoute
  '/offers/$id': typeof OffersIdIndexRoute
  '/profile/$id': typeof ProfileIdIndexRoute
  '/sandboxes/$id': typeof SandboxesIdIndexRoute
  '/profile/$id/achievements/$sandbox': typeof ProfileIdAchievementsSandboxRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/about': typeof AboutRoute
  '/changelog': typeof ChangelogRoute
  '/dashboard': typeof DashboardRoute
  '/donate-key': typeof DonateKeyRoute
  '/fair-use': typeof FairUseRoute
  '/freebies': typeof FreebiesRoute
  '/genres': typeof GenresRoute
  '/posts': typeof PostsRoute
  '/privacy': typeof PrivacyRoute
  '/search': typeof SearchRoute
  '/auth/callback': typeof AuthCallbackRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/logout': typeof AuthLogoutRoute
  '/builds/$id': typeof BuildsIdRouteWithChildren
  '/collections/$id': typeof CollectionsIdRoute
  '/items/$id': typeof ItemsIdRouteWithChildren
  '/offers/$id': typeof OffersIdRouteWithChildren
  '/profile/$id': typeof ProfileIdRouteWithChildren
  '/promotions/$id': typeof PromotionsIdRoute
  '/sales/$id': typeof SalesIdRoute
  '/sandboxes/$id': typeof SandboxesIdRouteWithChildren
  '/sellers/$id': typeof SellersIdRoute
  '/tags/$id': typeof TagsIdRoute
  '/collections/': typeof CollectionsIndexRoute
  '/sales/': typeof SalesIndexRoute
  '/builds/$id/files': typeof BuildsIdFilesRoute
  '/builds/$id/install-options': typeof BuildsIdInstallOptionsRoute
  '/builds/$id/items': typeof BuildsIdItemsRoute
  '/items/$id/assets': typeof ItemsIdAssetsRoute
  '/items/$id/builds': typeof ItemsIdBuildsRoute
  '/items/$id/images': typeof ItemsIdImagesRoute
  '/offers/$id/achievements': typeof OffersIdAchievementsRoute
  '/offers/$id/changelog': typeof OffersIdChangelogRoute
  '/offers/$id/items': typeof OffersIdItemsRoute
  '/offers/$id/media': typeof OffersIdMediaRoute
  '/offers/$id/metadata': typeof OffersIdMetadataRoute
  '/offers/$id/price': typeof OffersIdPriceRoute
  '/offers/$id/related': typeof OffersIdRelatedRoute
  '/offers/$id/reviews': typeof OffersIdReviewsRoute
  '/sandboxes/$id/achievements': typeof SandboxesIdAchievementsRoute
  '/sandboxes/$id/assets': typeof SandboxesIdAssetsRoute
  '/sandboxes/$id/builds': typeof SandboxesIdBuildsRoute
  '/sandboxes/$id/items': typeof SandboxesIdItemsRoute
  '/sandboxes/$id/offers': typeof SandboxesIdOffersRoute
  '/items/$id/': typeof ItemsIdIndexRoute
  '/offers/$id/': typeof OffersIdIndexRoute
  '/profile/$id/': typeof ProfileIdIndexRoute
  '/sandboxes/$id/': typeof SandboxesIdIndexRoute
  '/profile/$id/achievements/$sandbox': typeof ProfileIdAchievementsSandboxRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/about'
    | '/changelog'
    | '/dashboard'
    | '/donate-key'
    | '/fair-use'
    | '/freebies'
    | '/genres'
    | '/posts'
    | '/privacy'
    | '/search'
    | '/auth/callback'
    | '/auth/login'
    | '/auth/logout'
    | '/builds/$id'
    | '/collections/$id'
    | '/items/$id'
    | '/offers/$id'
    | '/profile/$id'
    | '/promotions/$id'
    | '/sales/$id'
    | '/sandboxes/$id'
    | '/sellers/$id'
    | '/tags/$id'
    | '/collections'
    | '/sales'
    | '/builds/$id/files'
    | '/builds/$id/install-options'
    | '/builds/$id/items'
    | '/items/$id/assets'
    | '/items/$id/builds'
    | '/items/$id/images'
    | '/offers/$id/achievements'
    | '/offers/$id/changelog'
    | '/offers/$id/items'
    | '/offers/$id/media'
    | '/offers/$id/metadata'
    | '/offers/$id/price'
    | '/offers/$id/related'
    | '/offers/$id/reviews'
    | '/sandboxes/$id/achievements'
    | '/sandboxes/$id/assets'
    | '/sandboxes/$id/builds'
    | '/sandboxes/$id/items'
    | '/sandboxes/$id/offers'
    | '/items/$id/'
    | '/offers/$id/'
    | '/profile/$id/'
    | '/sandboxes/$id/'
    | '/profile/$id/achievements/$sandbox'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/about'
    | '/changelog'
    | '/dashboard'
    | '/donate-key'
    | '/fair-use'
    | '/freebies'
    | '/genres'
    | '/posts'
    | '/privacy'
    | '/search'
    | '/auth/callback'
    | '/auth/login'
    | '/auth/logout'
    | '/builds/$id'
    | '/collections/$id'
    | '/promotions/$id'
    | '/sales/$id'
    | '/sellers/$id'
    | '/tags/$id'
    | '/collections'
    | '/sales'
    | '/builds/$id/files'
    | '/builds/$id/install-options'
    | '/builds/$id/items'
    | '/items/$id/assets'
    | '/items/$id/builds'
    | '/items/$id/images'
    | '/offers/$id/achievements'
    | '/offers/$id/changelog'
    | '/offers/$id/items'
    | '/offers/$id/media'
    | '/offers/$id/metadata'
    | '/offers/$id/price'
    | '/offers/$id/related'
    | '/offers/$id/reviews'
    | '/sandboxes/$id/achievements'
    | '/sandboxes/$id/assets'
    | '/sandboxes/$id/builds'
    | '/sandboxes/$id/items'
    | '/sandboxes/$id/offers'
    | '/items/$id'
    | '/offers/$id'
    | '/profile/$id'
    | '/sandboxes/$id'
    | '/profile/$id/achievements/$sandbox'
  id:
    | '__root__'
    | '/'
    | '/about'
    | '/changelog'
    | '/dashboard'
    | '/donate-key'
    | '/fair-use'
    | '/freebies'
    | '/genres'
    | '/posts'
    | '/privacy'
    | '/search'
    | '/auth/callback'
    | '/auth/login'
    | '/auth/logout'
    | '/builds/$id'
    | '/collections/$id'
    | '/items/$id'
    | '/offers/$id'
    | '/profile/$id'
    | '/promotions/$id'
    | '/sales/$id'
    | '/sandboxes/$id'
    | '/sellers/$id'
    | '/tags/$id'
    | '/collections/'
    | '/sales/'
    | '/builds/$id/files'
    | '/builds/$id/install-options'
    | '/builds/$id/items'
    | '/items/$id/assets'
    | '/items/$id/builds'
    | '/items/$id/images'
    | '/offers/$id/achievements'
    | '/offers/$id/changelog'
    | '/offers/$id/items'
    | '/offers/$id/media'
    | '/offers/$id/metadata'
    | '/offers/$id/price'
    | '/offers/$id/related'
    | '/offers/$id/reviews'
    | '/sandboxes/$id/achievements'
    | '/sandboxes/$id/assets'
    | '/sandboxes/$id/builds'
    | '/sandboxes/$id/items'
    | '/sandboxes/$id/offers'
    | '/items/$id/'
    | '/offers/$id/'
    | '/profile/$id/'
    | '/sandboxes/$id/'
    | '/profile/$id/achievements/$sandbox'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AboutRoute: typeof AboutRoute
  ChangelogRoute: typeof ChangelogRoute
  DashboardRoute: typeof DashboardRoute
  DonateKeyRoute: typeof DonateKeyRoute
  FairUseRoute: typeof FairUseRoute
  FreebiesRoute: typeof FreebiesRoute
  GenresRoute: typeof GenresRoute
  PostsRoute: typeof PostsRoute
  PrivacyRoute: typeof PrivacyRoute
  SearchRoute: typeof SearchRoute
  AuthCallbackRoute: typeof AuthCallbackRoute
  AuthLoginRoute: typeof AuthLoginRoute
  AuthLogoutRoute: typeof AuthLogoutRoute
  BuildsIdRoute: typeof BuildsIdRouteWithChildren
  CollectionsIdRoute: typeof CollectionsIdRoute
  ItemsIdRoute: typeof ItemsIdRouteWithChildren
  OffersIdRoute: typeof OffersIdRouteWithChildren
  ProfileIdRoute: typeof ProfileIdRouteWithChildren
  PromotionsIdRoute: typeof PromotionsIdRoute
  SalesIdRoute: typeof SalesIdRoute
  SandboxesIdRoute: typeof SandboxesIdRouteWithChildren
  SellersIdRoute: typeof SellersIdRoute
  TagsIdRoute: typeof TagsIdRoute
  CollectionsIndexRoute: typeof CollectionsIndexRoute
  SalesIndexRoute: typeof SalesIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AboutRoute: AboutRoute,
  ChangelogRoute: ChangelogRoute,
  DashboardRoute: DashboardRoute,
  DonateKeyRoute: DonateKeyRoute,
  FairUseRoute: FairUseRoute,
  FreebiesRoute: FreebiesRoute,
  GenresRoute: GenresRoute,
  PostsRoute: PostsRoute,
  PrivacyRoute: PrivacyRoute,
  SearchRoute: SearchRoute,
  AuthCallbackRoute: AuthCallbackRoute,
  AuthLoginRoute: AuthLoginRoute,
  AuthLogoutRoute: AuthLogoutRoute,
  BuildsIdRoute: BuildsIdRouteWithChildren,
  CollectionsIdRoute: CollectionsIdRoute,
  ItemsIdRoute: ItemsIdRouteWithChildren,
  OffersIdRoute: OffersIdRouteWithChildren,
  ProfileIdRoute: ProfileIdRouteWithChildren,
  PromotionsIdRoute: PromotionsIdRoute,
  SalesIdRoute: SalesIdRoute,
  SandboxesIdRoute: SandboxesIdRouteWithChildren,
  SellersIdRoute: SellersIdRoute,
  TagsIdRoute: TagsIdRoute,
  CollectionsIndexRoute: CollectionsIndexRoute,
  SalesIndexRoute: SalesIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/about",
        "/changelog",
        "/dashboard",
        "/donate-key",
        "/fair-use",
        "/freebies",
        "/genres",
        "/posts",
        "/privacy",
        "/search",
        "/auth/callback",
        "/auth/login",
        "/auth/logout",
        "/builds/$id",
        "/collections/$id",
        "/items/$id",
        "/offers/$id",
        "/profile/$id",
        "/promotions/$id",
        "/sales/$id",
        "/sandboxes/$id",
        "/sellers/$id",
        "/tags/$id",
        "/collections/",
        "/sales/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/about": {
      "filePath": "about.tsx"
    },
    "/changelog": {
      "filePath": "changelog.tsx"
    },
    "/dashboard": {
      "filePath": "dashboard.tsx"
    },
    "/donate-key": {
      "filePath": "donate-key.tsx"
    },
    "/fair-use": {
      "filePath": "fair-use.tsx"
    },
    "/freebies": {
      "filePath": "freebies.tsx"
    },
    "/genres": {
      "filePath": "genres.tsx"
    },
    "/posts": {
      "filePath": "posts.tsx"
    },
    "/privacy": {
      "filePath": "privacy.tsx"
    },
    "/search": {
      "filePath": "search.tsx"
    },
    "/auth/callback": {
      "filePath": "auth/callback.tsx"
    },
    "/auth/login": {
      "filePath": "auth/login.tsx"
    },
    "/auth/logout": {
      "filePath": "auth/logout.tsx"
    },
    "/builds/$id": {
      "filePath": "builds/$id.tsx",
      "children": [
        "/builds/$id/files",
        "/builds/$id/install-options",
        "/builds/$id/items"
      ]
    },
    "/collections/$id": {
      "filePath": "collections/$id.tsx"
    },
    "/items/$id": {
      "filePath": "items/$id.tsx",
      "children": [
        "/items/$id/assets",
        "/items/$id/builds",
        "/items/$id/images",
        "/items/$id/"
      ]
    },
    "/offers/$id": {
      "filePath": "offers/$id.tsx",
      "children": [
        "/offers/$id/achievements",
        "/offers/$id/changelog",
        "/offers/$id/items",
        "/offers/$id/media",
        "/offers/$id/metadata",
        "/offers/$id/price",
        "/offers/$id/related",
        "/offers/$id/reviews",
        "/offers/$id/"
      ]
    },
    "/profile/$id": {
      "filePath": "profile/$id.tsx",
      "children": [
        "/profile/$id/",
        "/profile/$id/achievements/$sandbox"
      ]
    },
    "/promotions/$id": {
      "filePath": "promotions/$id.tsx"
    },
    "/sales/$id": {
      "filePath": "sales/$id.tsx"
    },
    "/sandboxes/$id": {
      "filePath": "sandboxes/$id.tsx",
      "children": [
        "/sandboxes/$id/achievements",
        "/sandboxes/$id/assets",
        "/sandboxes/$id/builds",
        "/sandboxes/$id/items",
        "/sandboxes/$id/offers",
        "/sandboxes/$id/"
      ]
    },
    "/sellers/$id": {
      "filePath": "sellers/$id.tsx"
    },
    "/tags/$id": {
      "filePath": "tags/$id.tsx"
    },
    "/collections/": {
      "filePath": "collections/index.tsx"
    },
    "/sales/": {
      "filePath": "sales/index.tsx"
    },
    "/builds/$id/files": {
      "filePath": "builds/$id/files.tsx",
      "parent": "/builds/$id"
    },
    "/builds/$id/install-options": {
      "filePath": "builds/$id/install-options.tsx",
      "parent": "/builds/$id"
    },
    "/builds/$id/items": {
      "filePath": "builds/$id/items.tsx",
      "parent": "/builds/$id"
    },
    "/items/$id/assets": {
      "filePath": "items/$id/assets.tsx",
      "parent": "/items/$id"
    },
    "/items/$id/builds": {
      "filePath": "items/$id/builds.tsx",
      "parent": "/items/$id"
    },
    "/items/$id/images": {
      "filePath": "items/$id/images.tsx",
      "parent": "/items/$id"
    },
    "/offers/$id/achievements": {
      "filePath": "offers/$id/achievements.tsx",
      "parent": "/offers/$id"
    },
    "/offers/$id/changelog": {
      "filePath": "offers/$id/changelog.tsx",
      "parent": "/offers/$id"
    },
    "/offers/$id/items": {
      "filePath": "offers/$id/items.tsx",
      "parent": "/offers/$id"
    },
    "/offers/$id/media": {
      "filePath": "offers/$id/media.tsx",
      "parent": "/offers/$id"
    },
    "/offers/$id/metadata": {
      "filePath": "offers/$id/metadata.tsx",
      "parent": "/offers/$id"
    },
    "/offers/$id/price": {
      "filePath": "offers/$id/price.tsx",
      "parent": "/offers/$id"
    },
    "/offers/$id/related": {
      "filePath": "offers/$id/related.tsx",
      "parent": "/offers/$id"
    },
    "/offers/$id/reviews": {
      "filePath": "offers/$id/reviews.tsx",
      "parent": "/offers/$id"
    },
    "/sandboxes/$id/achievements": {
      "filePath": "sandboxes/$id/achievements.tsx",
      "parent": "/sandboxes/$id"
    },
    "/sandboxes/$id/assets": {
      "filePath": "sandboxes/$id/assets.tsx",
      "parent": "/sandboxes/$id"
    },
    "/sandboxes/$id/builds": {
      "filePath": "sandboxes/$id/builds.tsx",
      "parent": "/sandboxes/$id"
    },
    "/sandboxes/$id/items": {
      "filePath": "sandboxes/$id/items.tsx",
      "parent": "/sandboxes/$id"
    },
    "/sandboxes/$id/offers": {
      "filePath": "sandboxes/$id/offers.tsx",
      "parent": "/sandboxes/$id"
    },
    "/items/$id/": {
      "filePath": "items/$id/index.tsx",
      "parent": "/items/$id"
    },
    "/offers/$id/": {
      "filePath": "offers/$id/index.tsx",
      "parent": "/offers/$id"
    },
    "/profile/$id/": {
      "filePath": "profile/$id/index.tsx",
      "parent": "/profile/$id"
    },
    "/sandboxes/$id/": {
      "filePath": "sandboxes/$id/index.tsx",
      "parent": "/sandboxes/$id"
    },
    "/profile/$id/achievements/$sandbox": {
      "filePath": "profile/$id/achievements/$sandbox.tsx",
      "parent": "/profile/$id"
    }
  }
}
ROUTE_MANIFEST_END */
